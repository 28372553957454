import React from 'react'
import SEO from '../components/SEO'
import { Heading, Container, Section } from 'react-bulma-components';

const PrivacyPolicy = () => {
  return (
    <>
      <SEO title="Behandling av personuppgifter | Wallang" />
      <Container>
        <Section className="info-section">
          <Heading sieze={2} className="has-text-centered">
            Personuppgifter
          </Heading>

          <h2 className="info-section__header">Vad är en personuppgift och vad är en behandling av personuppgifter?</h2>
          <p>
            Personuppgifter är alla uppgifter om en levande fysisk person som direkt eller indirekt kan kopplas till den
            personen. Det handlar inte bara om namn och personnummer utan även om till exempel bilder och
            e-postadresser.
          </p>
          <p>
            Behandling av personuppgifter är allt som sker med personuppgifterna i IT-system, oavsett om det handlar om
            mobila enheter eller datorer. Det handlar om till exempel insamling, registrering, strukturering, lagring,
            bearbetning och överföring.
          </p>

          <h2 className="info-section__header">Hur vi skyddar dina personuppgifter</h2>
          <p>
            När du ger ifrån dig uppgifter om dig själv i samband med köp ska du alltid kunna vara trygg med att vi
            hanterar dina personuppgifter på ett säkert sätt. Känslig information såsom kontokortsnummer skickas alltid
            krypterad (SSL) för att utomstående inte ska kunna komma åt informationen. Informationen lagras inte av oss
            på Wallang utan skickas endast vidare till Klarna. Överallt där känslig data skickas är överföringen
            krypterad.
          </p>

          <h2 className="info-section__header">Vad vi sparar och varför</h2>
          <p>
            När du lägger en order sparar vi namn, postadress, telefonnummer, e-postadress, adress, leverans- och
            betalningsuppgifter. Vår betalpartner Klarna kräver även att du uppger ditt personnummer för att kunna
            bedöma din kreditvärdighet. När Klarna godkänner din betalning får vi ditt personnummer av dem för att hålla
            reda på att du är du. Du kan givetvis också handla hos oss utan att uppge ditt personnummer.
          </p>
          <p>
            För att kunna skicka information till dig om din order sparar vi din e-postadress och ditt telefonnummer.
            Din adress behöver vi för att kunna leverera ditt paket på bästa sätt. De personuppgifter vi får i samband
            med köp sparas också för eventuell framtida support, reklamationer och garantier.
          </p>

          <h2 className="info-section__header">Så länge sparar vi personuppgifter</h2>
          <p>
            Personuppgifter sparas under den tid det finns en pågående användarrelation samt i enlighet med lämnat
            samtycke. Vi behandlar inte kunddata som är äldre än två år men för vår bokförings skull behöver vi spara
            köphistorik i sju år.
          </p>

          <h2 className="info-section__header">Dina rättigheter</h2>
          <p>
            Du har alltid rätt till att när som helst få ett utdrag på vilken information vi har om dig och få veta i
            vilket syfte personuppgifterna används. Skulle du inte vara enig med hur vi använder dina personuppgifter
            har du rätt till att göra invändningar samt ta tillbaka ditt samtycke. Du har också rätt att begära att vi
            tar bort alla personuppgifter vi har om dig. Du är varmt välkommen att kontakta oss på{' '}
            <a href="mailto:info@wallang.se">info@wallang.se</a> så kommer vi besvara de frågor och funderingar du har.
          </p>
        </Section>
      </Container>
    </>
  )
}

export default PrivacyPolicy;